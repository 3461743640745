import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { resourceService } from '../../../domain/services/resourceService';
import { theme } from '../../../domain/theme';
import {
  mapContentToCardData,
  removeAuthorsAndDateFromCardData
} from '../../../domain/utils/contentUtils';
import { PreHeadline } from '../atoms/PreHeadline';
import { Headline } from '../atoms/Headline';
import { ContainerMain } from '../atoms/ContainerMain';
import { Loading } from '../atoms/Loading';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';
import { CardItemVerticalLegacy } from '../organisms/CardItemVerticalLegacy';
import { removeHtml } from '../../../domain/utils/stringUtils';
import { GridColumn, GridRow } from '../atoms/Grid';
import { ContentTakeOver } from '../organisms/ContentTakeOver';
import { ContainerFluid } from '../atoms/ContainerFluid';
import { t } from '../../../domain/services/configService';

// Since this is a template, we can customize its spacings.
// Note that this strategy must be confirmed true when
// developing other pieces of atomic design, so it could change.
const styles = {
  main: css`
    margin-top: ${theme.spacing('xs')};
  `
};

const ITEMS_PER_PAGE = 10;

export const PhotographerTemplate = ({ content }) => {
  const [secondaryEntities, setSecondaryEntities] = useState([]);
  const [page, hasMore, setHasMore, loaderRef] = useInfiniteScroll({});

  useEffect(() => {
    const fetchData = async () => {
      const start = page * ITEMS_PER_PAGE;
      const end = start + ITEMS_PER_PAGE;
      const pageContents =
        content.relatedContents.data?.content?.pagination.slice(start, end);
      const promises = [];
      let contentIndex = -1;
      const pageContentsIndex = pageContents.map(entity =>
        entity.content.map(relativeUrl => {
          promises.push(resourceService.getJsonForPathname(relativeUrl));
          contentIndex += 1;
          return contentIndex;
        })
      );
      const contentValues = await Promise.all(promises);
      const secondaryEntitiesForThisPage = pageContents.map((entity, index) => {
        const secondaryContent = pageContentsIndex[index];
        return {
          content: secondaryContent.map(thisContentIndex =>
            removeAuthorsAndDateFromCardData(
              mapContentToCardData(contentValues[thisContentIndex])
            )
          ),
          imageEntity: entity.imageEntity
        };
      });
      setHasMore(!!content.relatedContents.data.content.pagination[end + 1]);
      setSecondaryEntities(prev => [...prev, ...secondaryEntitiesForThisPage]);
    };

    if (page !== null && content.relatedContents.data.content?.pagination) {
      fetchData();
    }
  }, [page]);

  const entities = content.relatedContents?.data?.content?.featured
    ? content.relatedContents.data.content.featured.concat(secondaryEntities)
    : [];

  const cardItemsPages = [];
  if (entities.length >= 1) {
    let pageIndex = -1;
    const cards = entities.filter(
      entity =>
        entity.imageEntity?.mediaImage !== null &&
        entity.imageEntity?.mediaImage
    );
    cards.forEach((entity, index) => {
      if (index % ITEMS_PER_PAGE === 0) {
        pageIndex += 1;
      }
      if (!cardItemsPages[pageIndex]) {
        cardItemsPages[pageIndex] = [];
      }
      const id = entity.imageEntity.mediaImage.url
        ? entity.imageEntity.mediaImage.url
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        : index;
      const titles = [];
      entity.content.forEach(element => {
        titles.push(`${element.title.trim()}${element.bundle}`);
      });
      const original = entity.content.filter(element => {
        if (
          titles.includes(`${element.title.trim()}article`) &&
          `${element.title.trim()}${element.bundle}` !==
            `${element.title.trim()}article`
        ) {
          return false;
        }
        return true;
      });
      cardItemsPages[pageIndex].push({
        data: {
          type: 'article',
          imageEntity: entity.imageEntity,
          title:
            entity.imageEntity.caption &&
            removeHtml(entity.imageEntity.caption),
          url: original.length > 1 ? `#${id}` : original[0].url,
          target: '_blank'
        },
        original
      });
    });
    if (cardItemsPages[0]?.[0]) {
      cardItemsPages[0][0].isAboveTheFold = true;
    }
  }

  return (
    <div css={styles.main}>
      <ContainerMain>
        <PreHeadline>
          <span>{t('Photographer Page')}</span>
        </PreHeadline>
        <Headline>{content.title}</Headline>
      </ContainerMain>
      {cardItemsPages[0]?.length >= 1 &&
        cardItemsPages.map(cardItems => {
          return (
            <React.Fragment // eslint-disable-next-line
              key={cardItems[0].data.imageEntity.mediaImage.image.id}
            >
              <ContainerFluid>
                {cardItems[0].original.length > 1 ? (
                  <ContentTakeOver
                    imageEntity={cardItems[0].data.imageEntity}
                    content={cardItems[0].original}
                  >
                    <CardItemVerticalLegacy
                      size="photoLarge"
                      data={cardItems[0].data}
                      isAboveTheFold={cardItems[0].isAboveTheFold}
                    />
                  </ContentTakeOver>
                ) : (
                  <CardItemVerticalLegacy
                    size="photoLarge"
                    data={cardItems[0].data}
                    isAboveTheFold={cardItems[0].isAboveTheFold}
                  />
                )}
              </ContainerFluid>
              <ContainerMain>
                <GridRow gutter="xs">
                  {cardItems.map((cardItem, index) => {
                    if (index !== 0) {
                      return (
                        <GridColumn
                          width={[12, 4, 4, 4]}
                          childrenMarginBottom="l"
                          key={cardItem.data.imageEntity.mediaImage.image.id}
                        >
                          {cardItem.original.length > 1 ? (
                            <ContentTakeOver
                              imageEntity={cardItem.data.imageEntity}
                              content={cardItem.original}
                            >
                              <CardItemVerticalLegacy
                                size="photoThird"
                                data={cardItem.data}
                                aspect="16x9"
                                imageMaxWidths={{
                                  mobile: 703,
                                  tablet: 309,
                                  small_desktop: 379,
                                  large_desktop: 485
                                }}
                              />
                            </ContentTakeOver>
                          ) : (
                            <CardItemVerticalLegacy
                              size="photoThird"
                              data={cardItem.data}
                              aspect="16x9"
                              imageMaxWidths={{
                                mobile: 703,
                                tablet: 309,
                                small_desktop: 379,
                                large_desktop: 485
                              }}
                            />
                          )}
                        </GridColumn>
                      );
                    }
                    return null;
                  })}
                </GridRow>
              </ContainerMain>
            </React.Fragment>
          );
        })}

      {hasMore && (
        <div ref={loaderRef}>
          <Loading />
        </div>
      )}
    </div>
  );
};

PhotographerTemplate.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
